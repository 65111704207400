 <template>
	<el-dialog top="1vh" title="支付审核通过" width="600px" :visible.sync="this_is_show">
		<el-form label-width="68px" style="padding-top:0;">
			<el-form-item label="共计支付">
				<el-input v-model="passed_total" disabled>
					<el-button slot="append">元</el-button>
				</el-input>
			</el-form-item>
			<el-form-item label="支付密码">
				<el-input v-model="pay_pwd" type="password" clearable>
					<el-button type="success" @click="reset_pay_pwd_open" slot="append">
						<i class="el-icon-edit"></i>
					</el-button>
				</el-input>
			</el-form-item>
			<div style="text-align:center">
				<el-button type="primary" @click="sub()">提交</el-button>
			</div>
		</el-form>

		<!-- 重置支付密码 -->
		<el-dialog
			top="3vh" width="500px" 
			title="重置支付密码"
			append-to-body 
			custom-class="el-dialog-s" 
			:visible.sync="reset_pay_pwd_is_show"
		>
			<payPwdReset @done="reset_pay_pwd_close"></payPwdReset>
		</el-dialog>

	</el-dialog>
</template>

<script>
	import payPwdReset from '@/pages/user/act/pay_pwd.vue'
	export default {
		props:{
			need_pass_list:Array,
			is_show:Number,
		},
		components:{
			payPwdReset
		},
		data() {
			return {

				//重置支付密码页面是否显示
				reset_pay_pwd_is_show:false,

				//页面是否显示
				this_is_show:false,

				//本次通过金额
				passed_total:0,

				//支付密码
				pay_pwd:'',

				//需要提交的数据
				pass_list_in_page:[]
			}
		},
		watch:{
			is_show(new_data){
				if(new_data==0){
					this.this_is_show=false
				}else {

					//清空支付密码
					this.pay_pwd=""

					//清空本次通过金额
					this.passed_total=0

					//计算本次通过金额
					this.passed_total_calc()

					//打开
					this.this_is_show=true
				}
			},
		},
		methods: {

			//计算本次通过金额
			passed_total_calc(){
				let passed_total=0;
				let pass_list_in_page=[];
				for(var item of this.need_pass_list){
					passed_total+=parseFloat(item.this_payed)
					pass_list_in_page.push({
						truck_tord_num:item.truck_tord_num,
						payed_num:item.payed_num,
					})
				}
				this.passed_total=passed_total.toFixed(2);
				this.pass_list_in_page=pass_list_in_page;
			},

			//提交
			sub(){

				if(!this.pay_pwd.trim()){
					this.$my.other.msg({
						type:'warning',
						str:"缺少支付密码"
					});
					return;
				}
				//调用接口
				this.$my.net.req({
					data:{
						mod:'truck_tord_real_time',
						ctr:'tord_pay_check_pass_by_shipper',
						pay_pwd:this.pay_pwd,
						pass_list:this.pass_list_in_page,
					},
					callback:(data)=>{

						//通知
						this.$emit('passed')
					}
				})
			},

			//打开重置支付密码页面
			reset_pay_pwd_open(){
				this.reset_pay_pwd_is_show=true;
			},
			reset_pay_pwd_close(){//关闭重置支付密码页面
				this.reset_pay_pwd_is_show=false;
			},
		}
	}
</script>
<style lang="scss" scoped>

	.el-dialog-s{
		z-index: 11;
	}
</style>